import { LeftOutlined } from '@ant-design/icons';
import { HorizontalLine } from '@components/HorizontalLine';
import NoMatch from '@components/NoMatch';
import routes from '@routes';
import { Skeleton, Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderDetail } from './list-order-detail/OrderDetail';
import UserOrderDetail from './list-order-detail/UserOrderDetail';
import { medicalApi } from '@apis/medical.api';
import { PrescribedPackDetail } from './list-order-detail/PrescribedPackDetail';
import { OrderDetailType } from '@models/OrderMedical.model';
import { ColumnsType } from 'antd/lib/table';
import { DiagnosticQuestionUserAnswersDetail } from '@models/Diagnostic.model';
import { handleSetAnaProgramIsQuicklyScreen } from '@state/ana-program/handleSetStateAnaProgram/handleSetStateAnaProgram';
import { useSelector } from 'react-redux';
import { RootState } from '@state/rootReducer';
import { ChatArea } from '@components/ChatArea';
import UserDetailModel from '@models/UserDetail.model';
import accountApi from '@apis/account.api';

export const OrderDetailPage = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [orderDetailData, setOrderDetailData] = useState<OrderDetailType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChatLoading, setIsChatLoading] = useState<boolean>(false);
  const [userDetail, setUserDetail] = useState<UserDetailModel>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isClick, setIsClick] = useState<boolean>(
    useSelector((state: RootState) => state.anaProgram.isQuicklyScreen)
  );
  const columns: ColumnsType<DiagnosticQuestionUserAnswersDetail> = [
    {
      title: 'No.',
      className: 'column-first-item ',
      width: '4%',
      key: 'no',
      render: (data: DiagnosticQuestionUserAnswersDetail) => (
        <p className="m-0 p-0">
          {orderDetailData?.diagnosticQuestionUserAnswers
            ? orderDetailData?.diagnosticQuestionUserAnswers?.indexOf(data) + 1
            : 0}
        </p>
      ),
    },

    {
      title: 'Question',
      dataIndex: 'question',
      className: '',
      key: 'question',
      width: '46%',
      render: (question) => {
        return <p className=" p-0 m-0 w-full">{question}</p>;
      },
    },
    {
      title: 'Answer',
      key: 'answers',
      className: 'column-last-item ',
      dataIndex: 'answers',
      width: '46%',
      render: (answers: string[]) => {
        return (
          <div className="m-0 p-0 flex flex-col">
            {answers.map((answer, index) => (
              <p key={index} className="m-0 p-0">
                {answer}
              </p>
            ))}
          </div>
        );
      },
    },
  ];

  const handleGetUserById = async (userId: string) => {
    try {
      setIsLoading(true);
      const response = await accountApi.getUser(userId ? userId : '');
      if (response.data.success) {
        setUserDetail(response.data.data);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const toggleActive = (value: boolean) => {
    setIsActive(value);
  };

  const toggleClick = (value: boolean) => {
    setIsClick(value);
  };

  const handleIsChatLoading = (value: boolean) => {
    setIsChatLoading(value);
  };

  const handleGetOrderDetail = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await medicalApi.getMedicalOrderDetail(orderId ?? '');
      if (response.data.success) {
        setOrderDetailData(response.data.data);
        handleGetUserById(response.data.data.user.userId);
      }
      setIsLoading(false);
    } catch (error) {}
  }, [orderId]);
  useEffect(() => {
    handleGetOrderDetail();
  }, [handleGetOrderDetail]);
  return (
    <div className="h-full overflow-auto">
      {isLoading ? (
        <Skeleton className="p-[40px]" />
      ) : (
        <div>
          {orderDetailData ? (
            <div className="p-[40px] h-full  ">
              <div className={`flex flex-row justify-left items-center gap-8`}>
                <LeftOutlined
                  className={`text-primary text-[22px]`}
                  onClick={() => {
                    handleSetAnaProgramIsQuicklyScreen(false);
                    navigate(routes.ListOfOrder.path);
                  }}
                />
                <div className={`text-3xl text-primary font-bold flex flex-row`}>
                  Medical orders<div className="font-normal">/{orderId}</div>
                </div>
              </div>
              <UserOrderDetail
                userDetail={orderDetailData.user}
                orderId={orderId ?? ''}
                reloadData={handleGetOrderDetail}
                isEditDisable={orderDetailData.prescribedPack.length === 0}
              />
              {orderDetailData?.diagnosticQuestionUserAnswers?.length !== 0 && (
                <>
                  <div className="flex flex-row justify-between mt-[40px] mb-[8px]">
                    <p className="text-[24px] font-bold text-[#2F2F2F] ">
                      Answer to all questions in the diagnostics
                    </p>
                    <p
                      className="text-primary hover:underline font-medium text-[16px] mt-[12px] cursor-pointer"
                      onClick={() => {
                        setIsChatLoading(true);
                        setIsActive(true);
                        setIsClick(true);
                        handleSetAnaProgramIsQuicklyScreen(true);
                      }}
                    >
                      View user chat
                    </p>
                  </div>
                  <div className="">
                    <Table
                      className="table-user-profile "
                      columns={columns}
                      dataSource={orderDetailData?.diagnosticQuestionUserAnswers}
                      pagination={false}
                      scroll={{
                        y: 444,
                      }}
                    />
                  </div>
                </>
              )}

              {orderDetailData.prescribedPack.length > 0 && (
                <>
                  <div className="mt-[32px] mb-[32px]">
                    <HorizontalLine />
                  </div>
                  <div>
                    <PrescribedPackDetail
                      orderId={orderId ?? ''}
                      reloadData={handleGetOrderDetail}
                      status={orderDetailData.status}
                      data={orderDetailData.prescribedPack}
                      governmentIssueImg={orderDetailData?.user?.identifications ?? []}
                    />
                  </div>
                </>
              )}

              {orderDetailData?.orderSummary.length > 0 && (
                <OrderDetail OrderSummaryData={orderDetailData?.orderSummary} />
              )}
            </div>
          ) : (
            <NoMatch
              title=""
              content="This user has not complete diagnostic"
              description="Back to user profile page"
              pathName="/user-profile"
              className={`absolute w-full flex flex-col items-center  h-fit justify-center space-y-5 m-auto p-[120px]`}
            />
          )}
          {isClick && (
            <ChatArea
              isActive={isActive}
              userDetail={userDetail}
              isChatLoading={isChatLoading}
              toggleActive={toggleActive}
              toggleClick={toggleClick}
              handleIsLoading={handleIsChatLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

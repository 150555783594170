import { MedicalStatement } from '@constants/medical-statement';

import { useCallback, useEffect, useState } from 'react';
import SearchButton from '@components/SearchButton';
import Table, { ColumnsType } from 'antd/lib/table';
import { MedicalStatementModal } from './medical-statement-card/medical-statement-modal';
import './list-medical-statement.styles.scss';
import { naturopathicApi } from '@apis/naturopathic.api';
import { ConvertCountryCodeToName } from '@utils/convertCountryCodeToName.util';

export const MedicalStatementList = () => {
  const [medicalStatement, setMedicalStatementList] = useState<MedicalStatement[]>();
  const [editData, setEditData] = useState<MedicalStatement>();
  const [searchData, setSearchData] = useState<MedicalStatement[]>([]);
  const [toggleModel, setToggleModel] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const editStatement = (data: MedicalStatement) => {
    setEditData(data);
    setToggleModel(true);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filtered = medicalStatement?.filter(
      (item) =>
        item.naturopathicPack.toLowerCase().includes(value.toLowerCase()) ||
        item.naturopathicName.toLowerCase().includes(value.toLowerCase())
    );
    setSearchData(filtered ?? []);
  };

  const columns: ColumnsType<MedicalStatement> = [
    {
      title: 'No.',
      className: 'column-first-item ',
      width: '4%',
      key: 'no',
      render: (data: MedicalStatement) => (
        <p className="m-0 p-0">{medicalStatement ? medicalStatement.indexOf(data) + 1 : 0}</p>
      ),
    },
    {
      title: 'Pack',
      key: 'naturopathicPack',
      dataIndex: 'naturopathicPack',
    },
    {
      title: 'Naturopathic',
      key: 'naturopathicName',
      dataIndex: 'naturopathicName',
    },
    {
      title: 'Country Code',
      key: 'countryCode',
      dataIndex: 'countryCode',
      render: (countryCode: string) => (
        <p className="m-0 p-0">{ConvertCountryCodeToName(countryCode)}</p>
      ),
    },
    {
      title: 'Statement',
      key: 'statement',

      dataIndex: 'statement',

      render: (statement: string) => {
        return <p className="m-0 p-0 line-clamp-2">{statement}</p>;
      },
    },
    {
      title: 'Action',
      className: 'column-last-item ',
      render: (data: MedicalStatement) => (
        <div
          className="underline text-[#2F6BFF] cursor-pointer"
          onClick={() => editStatement(data)}
        >
          Edit
        </div>
      ),
    },
  ];

  const handleGetListStatement = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await naturopathicApi.getListStatement();
      if (response.data.success) {
        setMedicalStatementList(response.data.data.naturopathicStatements);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    handleGetListStatement();
  }, [handleGetListStatement]);

  return (
    <div className="p-10">
      <div className="flex flex-row justify-between w-full h-fit">
        <p className="text-3xl text-primary font-bold w-[300px] mt-[10px]">Medical Statement</p>
        <SearchButton onClick={handleSearch} searchText={searchText} />
      </div>
      <div>
        <Table
          className="table-user-profile"
          loading={isLoading}
          rowKey="key"
          columns={columns}
          dataSource={searchData.length === 0 && !searchText ? medicalStatement : searchData}
          pagination={false}
        />
      </div>
      {toggleModel && (
        <MedicalStatementModal
          data={editData}
          onEditOk={() => {
            if (searchData.length !== 0 && searchText) {
              setSearchText('');
              setSearchData([]);
            }
            setToggleModel(false);
            handleGetListStatement();
          }}
          onOpen={toggleModel}
          onClose={() => setToggleModel(false)}
        />
      )}
    </div>
  );
};

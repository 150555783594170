import './SearchButton.styles.scss';
import Search from 'antd/lib/transfer/search';
import { ChangeEvent } from 'react';

interface SearchButtonProps {
  onClick: (value: string) => void;
  searchText?: string;
  isRatingPage?: boolean;
}

const SearchButton = ({ onClick, searchText, isRatingPage = false }: SearchButtonProps) => {
  const handleOnchange = (event: ChangeEvent<HTMLInputElement>) => {
    onClick(event.target.value);
  };
  return (
    <>
      <div
        className={`search-button ml-[24px]  ${isRatingPage ? `mt-[24px]` : 'mt-[32px]'} mr-[24px]`}
      >
        <Search
          value={searchText}
          onChange={(e) => handleOnchange(e as ChangeEvent<HTMLInputElement>)}
          placeholder="Search"
        ></Search>
      </div>
    </>
  );
};

export default SearchButton;

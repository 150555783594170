import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { PackType, ProductType } from '../ListOfProduct.page';
import { UploadImageMedicalProductForm } from './UploadImageMedicalProduct';
import './MedicalProductModal.style.scss';
import { CaretDown } from '@phosphor-icons/react';
import { ArrayOfNaturopathicListMedicalProduct } from './RenderOptionMedicalProduct';
import { medicalApi } from '@apis/medical.api';

interface Props {
  model: string;
  onOpen: boolean;
  onClose?: () => void;
  affirmationId?: string;
  onModalOk: () => void;
  onEditOk?: () => void;
  packList: PackType[];
  editData?: ProductType;
}

export const MedicalProductModal = ({
  onOpen,
  onModalOk,
  onClose = () => {},
  model,
  packList,
  editData,
}: Props) => {
  const [form] = Form.useForm();
  const [medicalProduct] = useState<ProductType | undefined>(editData);
  const [pack, setPack] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { confirm } = Modal;

  const handlePackChange = (value: number) => {
    setPack(value);
    form.setFieldValue('naturopathicCode', undefined);
  };

  const onFinish = async (value: any) => {
    try {
      setIsLoading(true);
      const payload = {
        packOrder: value.packOrder,
        naturopathicCode: value.naturopathicCode,
        region: value.region,
        name: value.name,
        image: value.mediaUrl,
        productNumber: value.productNumber,
        price: value.price,
        direction: value.direction,
      };
      let response;
      model === 'edit'
        ? (response = await medicalApi.editMedicalProduct(editData?.id ?? '', payload))
        : (response = await medicalApi.createMedicalProduct(payload));
      if (response.data.success) {
        message.success(
          model === 'edit' ? 'Edit medical product Successfull' : 'Add medical product Successfull'
        );
        onModalOk();
        onClose();
      }
      setIsLoading(false);
    } catch (error) {
      message.error(error as any);
    }
  };

  useEffect(() => {
    form.setFieldsValue(medicalProduct);
  }, [form, medicalProduct]);

  const icon = <ExclamationCircleOutlined style={{ color: '#D01616' }} />;
  const title = <h3 className={`font-bold text-primary text-left`}>{`Are you sure ?`}</h3>;
  const content = (
    <h4 className={`text-black font-normal text-[16px] text-center`}>
      If you exit every change will be lost!
    </h4>
  );
  const onOk = () => {
    onClose();
  };
  const onCancel = () => {
    return;
  };
  const bodyStyle = {
    background: 'white',
    borderRadius: 10,
    width: 'fit-content',
    padding: 'none',
  };
  const okButtonProps = {
    style: {
      borderRadius: 4,
      width: 90,
      height: 40,
      backgroundColor: '#4D4479',
      transition: 'filter 0.3s',
      fontFamily: "'WorkSans', sans-serif",
      fontSize: '14px',
    },
    onMouseEnter: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.currentTarget.style.filter = 'brightness(150%)';
    },
    onMouseLeave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.currentTarget.style.filter = 'none';
    },
  };
  const cancelButtonProps = {
    style: {
      fontFamily: "'WorkSans', sans-serif",
      borderRadius: 4,
      width: 90,
      height: 40,
      backgroundColor: '#F5F5F5',
      color: '#4D4479',
      fontSize: '14px',
    },
  };
  const handleCancel = () => {
    confirm({
      className: 'custom-modal',
      icon: icon,
      maskClosable: true,
      centered: true,
      width: 'fit-content',
      okText: 'Exit',
      cancelText: 'Cancel',
      autoFocusButton: null,
      title: title,
      content: content,
      onOk: onOk,
      onCancel: onCancel,
      bodyStyle: bodyStyle,
      okButtonProps: okButtonProps,
      cancelButtonProps: cancelButtonProps,
    });
  };

  return (
    <>
      <Modal
        centered
        destroyOnClose
        open={onOpen}
        onOk={onClose}
        onCancel={onClose}
        className={`medicalProduct-modal`}
        bodyStyle={{ backgroundColor: '#4D4479' }}
        title={
          <h2
            style={{
              fontWeight: 700,
              fontSize: 24,
              color: 'white',
              textAlign: 'center',
              marginTop: 20,
            }}
          >
            {model === 'edit' ? 'Edit Medical Product' : 'Add New Medical Product'}
          </h2>
        }
        width={'70%'}
        closeIcon={<CloseCircleOutlined className={`text-white text-[30px] mt-6 mr-6`} />}
        footer={null}
      >
        <div className={`flex flex-col px-8 py-0`}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              medicalProduct,
            }}
            onReset={onClose}
          >
            <div className=" flex flex-row gap-4 w-full">
              <Form.Item
                className="w-full "
                label={<p className={`text-white text-base m-0 p-0`}>Pack</p>}
                name="packOrder"
                rules={[{ required: true }]}
                required={false}
              >
                <Select
                  className="custom-select-modal !h-[50px]"
                  suffixIcon={<CaretDown size={16} color="white" />}
                  onChange={handlePackChange}
                  placeholder="Select Pack"
                  options={packList.map((item) => ({
                    value: item.packOrder,
                    label: item.pack,
                  }))}
                />
              </Form.Item>
              <Form.Item
                className="w-full"
                rules={[{ required: true }]}
                required={false}
                label={<p className={`text-white text-base m-0 p-0z`}>Naturopathic List</p>}
                name="naturopathicCode"
              >
                <Select
                  className="custom-select-modal !h-[50px]"
                  suffixIcon={<CaretDown size={16} color="white" />}
                  placeholder="Select Naturopathic List"
                  options={ArrayOfNaturopathicListMedicalProduct(
                    editData?.packOrder ?? pack,
                    packList
                  ).map((item) => ({
                    value: item.code,
                    label: item.name,
                  }))}
                />
              </Form.Item>
            </div>
            <div className=" flex flex-row gap-4 w-full">
              <Form.Item
                className="w-[30%]"
                label={<p className={`text-white text-base m-0 p-0`}>Region</p>}
                rules={[{ required: true }]}
                required={false}
                name="region"
              >
                <Select
                  className="custom-select-modal "
                  suffixIcon={<CaretDown size={16} color="white" />}
                  placeholder="Select Region"
                  options={[
                    {
                      value: 'us',
                      label: 'America',
                    },
                    {
                      value: 'ca',
                      label: 'Canada',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                className="w-full "
                label={<p className={`text-white text-base m-0 p-0`}>Product Name</p>}
                rules={[{ required: true }]}
                required={false}
                name="name"
              >
                <Input
                  className={`!h-[50px] border-[1px] border-solid border-white !bg-primary rounded-[10px] text-white focus:!border-white hover:!border-white`}
                  placeholder="Enter Product Name"
                />
              </Form.Item>
            </div>
            <Form.Item
              label={<p className={`text-white text-base m-0 p-0 w-full `}>Product Image</p>}
              name="mediaUrl"
              rules={[{ required: true }]}
              required={false}
            >
              <div className="w-full">
                <UploadImageMedicalProductForm
                  name="mediaUrl"
                  form={form}
                  hasNote={false}
                  preValue={model === 'edit' && editData ? editData.pictureUrl : ''}
                  dispatch={(value: string) => {}}
                  removeMedia={() => {}}
                />
              </div>
            </Form.Item>
            <div className=" flex flex-row gap-4 w-full">
              <Form.Item
                className="w-full"
                label={<p className={`text-white text-base m-0 p-0z`}>Product Number</p>}
                name="productNumber"
                rules={[
                  { required: true },
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Please enter numbers only',
                  },
                ]}
                required={false}
              >
                <Input
                  className={`!h-[50px] border-[1px] border-solid border-white !bg-primary rounded-[10px] text-white focus:!border-white hover:!border-white`}
                  placeholder="Enter Product Number"
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                className="w-full"
                label={<p className={`text-white text-base m-0 p-0`}>Product Price</p>}
                name="price"
                rules={[
                  { required: true },
                  {
                    pattern: /^-?\d+(\.\d+)?$/,
                    message: 'Please enter numbers only',
                  },
                ]}
                required={false}
              >
                <Input
                  className={`button-product-price`}
                  addonAfter="$"
                  placeholder="Enter Product Price"
                />
              </Form.Item>
            </div>
            <Form.Item
              className="w-full"
              label={<p className={`text-white text-base m-0 p-0`}>Product Directions</p>}
              name="direction"
            >
              <Input
                className={`!h-[50px] border-[1px] border-solid border-white !bg-primary rounded-[10px] text-white focus:!border-white hover:!border-white`}
                placeholder="Enter Product Directions"
              />
            </Form.Item>
            <div className="flex flex-row justify-between gap-3">
              <Button
                className={`w-full h-[60px] font-[700] rounded-[10px] bg-white text-primary border-none`}
                onClick={handleCancel}
              >
                Cancel
              </Button>

              <Button
                loading={isLoading}
                htmlType="submit"
                className={`w-full h-[60px] rounded-[10px] font-[700] bg-[#8B90C6] border-none`}
                type="primary"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

import loggedClient from '../https/loggedClient.http';

const baseMedical = '/admin/naturopathics';

export const naturopathicApi = {
  getListNaturopathics: () => loggedClient.get(`${baseMedical}`),
  getListStatement: () => loggedClient.get(`${baseMedical}/statements`),
  updateStatement: (naturopathicStatementId: string, payload: any) =>
    loggedClient.patch(`${baseMedical}/statements/${naturopathicStatementId}`, payload),
};

export const dataType = [
  {
    title: 'Order Details',
    value: 'orderDetails',
  },
  {
    title: 'Diagnostic results',
    value: 'diagnosticResults',
  },
];

export enum SummaryDiagnosesTitle {
  YouMayHave = 'You may have',
  CanRuleOut = "Can't rule out",
  NutraceuticalInfo = 'Nutraceutical information',
}

export interface OrderType {
  id: string;
  userName: string;
  requestDate: string;
  currency: string;
  authorizingProvider: string;
  authorizingDate: string;
  requestStatus: string;
  orderStatus: string;
}

export interface DiagnosticType {
  mode: string;
  updated: string;
}

export interface OrderDetailType {
  type: string;
  subject: string[];
  dose: string[];
  qty: number[];
  price: number[];
}

import {
  handleAnaProgramAddChatId,
  handleAnaProgramAddUserId,
  handleSetAnaProgramIsExpandFull,
  handleSetAnaProgramIsQuicklyScreen,
} from '@state/ana-program/handleSetStateAnaProgram/handleSetStateAnaProgram';
import UserInformationPrivateChat from './UserInformationPrivateChat';
import { store } from '@state/store';
import { addUserProfile, resetUserProfile } from '@state/user/userReducer';
import {
  handleSetCurrentChatItem,
  handleSetFirstLoading,
  handleSetSubscriptionExpired,
} from '@state/chat/handleSetStateChat/handleSetStateChat';
import { Spin } from 'antd';
import ViewChatTextArea from '@pages/private-chat/privateChatSubscription/ViewChatTextArea';
import ChatTextArea from '@pages/private-chat/privateChatSubscription/ChatTextArea';
import UserDetailModel from '@models/UserDetail.model';
import ArrowRight from '@assets/images/icons/arrow-right.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@state/rootReducer';
import { useCallback, useEffect, useState } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '@pages/private-chat/HandleAccessFirebase';
import './ChatArea.scss';
interface Props {
  isActive: boolean;
  userDetail: UserDetailModel | undefined;
  isChatLoading: boolean;
  toggleActive: (value: boolean) => void;
  toggleClick: (value: boolean) => void;
  handleIsLoading: (value: boolean) => void;
}

export const ChatArea = ({
  isActive,
  userDetail,
  isChatLoading,
  toggleActive,
  toggleClick,
  handleIsLoading,
}: Props) => {
  const currentChatItem = useSelector((state: RootState) => state.anaProgram.chatId);
  const [isNavigating, setIsNavigating] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleChangeList = async () => {
    handleSetAnaProgramIsExpandFull(true);
    handleSetFirstLoading(false);
    handleSetCurrentChatItem(currentChatItem);
    handleAnaProgramAddUserId(userDetail?.id || '');
    setIsNavigating(false);
    navigate('/privateChat');
  };
  const handleGetMessage = useCallback(async () => {
    const diagnosticChatId = `session-user-${userDetail?.id}`;
    handleSetCurrentChatItem(diagnosticChatId);
    handleAnaProgramAddChatId(diagnosticChatId);
    const getDetail = doc(
      collection(db, `ana-private-chat/private-chat/sessions`),
      diagnosticChatId
    );
    const getSubscriptionDetail = await getDoc(getDetail);
    handleSetSubscriptionExpired(getSubscriptionDetail.data()?.subscriptionExpired);

    handleGetUserDetail();
  }, []);

  const handleGetUserDetail = useCallback(() => {
    const payload = {
      id: userDetail?.id ?? '',
      userName: userDetail?.name ?? '',
      email: userDetail?.email ?? '',
    };

    store.dispatch(addUserProfile(payload));
  }, [userDetail]);
  useEffect(() => {
    handleGetMessage();
  }, [handleGetMessage]);
  if (!currentChatItem) return <>Loading</>;

  return (
    <div
      className={`absolute top-0 right-0 z-40 bg-white w-[600px] h-full shadow-lg ${
        isActive ? 'slide-left-detail' : 'slide-right-detail'
      }`}
    >
      <div className={`w-full h-full  flex flex-col`}>
        <div className="view-detail-chat-header h-[146px] w-full flex flex-row justify-between">
          <div className=" w-[95%]">
            <UserInformationPrivateChat
              userName={userDetail?.name}
              body={userDetail?.email || ''}
              isClick={false}
            ></UserInformationPrivateChat>
          </div>
          <div className={` pt-[40px] pr-[12px]  cursor-pointer`}>
            <img
              alt=""
              src={ArrowRight}
              onClick={() => {
                handleSetAnaProgramIsQuicklyScreen(false);
                toggleActive(false);
                setTimeout(() => {
                  handleIsLoading(false);
                  toggleClick(false);
                  store.dispatch(resetUserProfile());
                  handleSetFirstLoading(true);
                }, 998);
              }}
            />
          </div>
        </div>
        <div className="flex justify-center bg-[#E2EBFF] ">
          <p
            className="p-[4px] m-0 text-[16px] cursor-pointer text-[#2F6BFF] underline"
            onClick={() => {
              handleChangeList();
            }}
          >
            Direct to full chat screen {isNavigating && <Spin className="ml-[10px]" size="small" />}
          </p>
        </div>

        <ViewChatTextArea
          loadingDetailChat={isChatLoading}
          finishLoad={() => {
            handleIsLoading(false);
          }}
          currentChatItem={currentChatItem}
        />
        <ChatTextArea
          chatId={currentChatItem ? currentChatItem : ''}
          currentUserId={userDetail?.id ?? ''}
        />
      </div>
    </div>
  );
};

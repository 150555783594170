import { useNavigate, useParams } from 'react-router-dom';
import UserDetail from './component/UserDetail';
import { LeftOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import UserDiagnosticDetail from './diagnostic-result/UserProfileDiagnosticDetail';
import routes from '@routes';
import accountApi from '@apis/account.api';
import { Skeleton } from 'antd';
import UserDetailModel from '@models/UserDetail.model';
import diagnosticApi from '@apis/diagnostic.api';
import DiagnosticUserModel from '@models/Diagnostic.model';
import NoMatch from '@components/NoMatch';
import { handleSetAnaProgramIsQuicklyScreen } from '@state/ana-program/handleSetStateAnaProgram/handleSetStateAnaProgram';
import { dataType } from '@constants/user-profile';
import { OrderDetailPage } from './order-detail/order-detail';
import './UserProfileDiagnosticDetail.styles.scss';

const UserProfileDiagnosticPageDetail = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [active, setActive] = useState<string>(dataType[1].value);
  const [firstView, setFirstView] = useState<boolean>(true);
  const [userDetail, setUserDetail] = useState<UserDetailModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [diagnosticList, setDiagnosticList] = useState<DiagnosticUserModel[]>([]);

  const handleGetUserById = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await accountApi.getUser(userId ? userId : '');
      if (response.data.success) {
        setUserDetail(response.data.data);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [userId]);

  const handleGetDiagnosticByUserId = useCallback(async () => {
    try {
      const response = await diagnosticApi.getDiagnosticByUserId(userId ? userId : '');
      if (response.data.success) {
        setDiagnosticList(response.data.data.diagnosticMultiUsers);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    handleGetUserById();
    handleGetDiagnosticByUserId();
  }, [userId, handleGetUserById, handleGetDiagnosticByUserId]);

  const handleClick = (value: string) => {
    setActive(value);
    if (firstView) {
      setFirstView(false);
    }
  };
  return (
    <div className="h-full overflow-auto">
      {isLoading ? (
        <Skeleton className="p-[40px]" />
      ) : (
        <div>
          {diagnosticList.length > 0 ? (
            <div className="p-[40px] h-full  ">
              <div className={`flex flex-row justify-left items-center gap-8`}>
                <LeftOutlined
                  className={`text-primary text-[22px]`}
                  onClick={() => {
                    // navigate(`/user-profile/detail/${userName}`);
                    handleSetAnaProgramIsQuicklyScreen(false);
                    navigate(routes.UserProfile.path);
                  }}
                />
                <div className={`text-3xl text-primary font-bold flex flex-row`}>
                  User Profile<div className="font-normal">/{userDetail?.name}</div>
                  <div className="font-normal">/diagnostic detail</div>
                </div>
              </div>
              <UserDetail userDetail={userDetail} />
              <div>
                <div className="flex flex-row w-full mt-[40px] border-t-0 border-l-0 border-r-0 border-b-[1px] border-solid border-[#C2C2C2]">
                  {dataType.map((data) => (
                    <div
                      className={`${
                        data.value === active ? 'text-primary' : 'text-[#919191]'
                      } text-[20px] font-bold w-fit h-full mr-[16px] cursor-pointer`}
                      onClick={() => handleClick(data.value)}
                    >
                      {data.title}
                      {data.value === active ? (
                        <div
                          className={`w-full h-[5px]  bg-primary underline-container ${
                            !firstView
                              ? data.value === dataType[0].value
                                ? 'slide-left'
                                : 'slide-right'
                              : ''
                          }  mt-[10px]`}
                        ></div>
                      ) : (
                        <div />
                      )}
                    </div>
                  ))}
                </div>
                {active === dataType[0].value ? (
                  // <UserOrderPage data={orderData} />
                  <div>
                    <OrderDetailPage userId={userId ?? ''} />
                  </div>
                ) : (
                  <UserDiagnosticDetail diagnosticList={diagnosticList} userDetail={userDetail} />
                )}
              </div>
            </div>
          ) : (
            <NoMatch
              title=""
              content="This user has not complete diagnostic"
              description="Back to user profile page"
              pathName="/user-profile"
              className={`absolute w-full flex flex-col items-center  h-fit justify-center space-y-5 m-auto p-[120px]`}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserProfileDiagnosticPageDetail;

const FIRST_ITEM_TOP = 145;
const MENU_ITEMS_DISTANCE = 81;
export const INDICATOR_HEIGHT = 50;
const EXTRA_DISTANCE = 30;
const MINI_EXTRA_DISTANCE = 20;

export const getIndicator = (currentIndex: number | string, isOpen: boolean = false) => {
  const currentIndexNumber = parseFloat(currentIndex as string);
  if (currentIndexNumber < 5) return FIRST_ITEM_TOP + MENU_ITEMS_DISTANCE * currentIndexNumber;
  if (isOpen) {
    if (currentIndexNumber < 6)
      return (
        FIRST_ITEM_TOP +
        MENU_ITEMS_DISTANCE * (currentIndexNumber + (currentIndexNumber - 5) * 10) +
        EXTRA_DISTANCE +
        (1 - (currentIndexNumber - 5) * 10) * MINI_EXTRA_DISTANCE
      );
  } else {
    if (currentIndexNumber < 6)
      return (
        FIRST_ITEM_TOP + MENU_ITEMS_DISTANCE * Math.floor(currentIndexNumber) - MINI_EXTRA_DISTANCE
      );
    else return FIRST_ITEM_TOP + MENU_ITEMS_DISTANCE * currentIndexNumber - MINI_EXTRA_DISTANCE - 5;
  }
};

import accountApi from '@apis/account.api';
import { medicalApi } from '@apis/medical.api';
import { HorizontalLine } from '@components/HorizontalLine';
import { MedicalOrderUser, OrderDetailType } from '@models/OrderMedical.model';
import { OrderDetail } from '@pages/ana-program/list-of-order/list-order-detail/OrderDetail';
import { PrescribedPackDetail } from '@pages/ana-program/list-of-order/list-order-detail/PrescribedPackDetail';
import { Skeleton } from 'antd';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  userId: string;
}

export const OrderDetailPage = ({ userId }: Props) => {
  const [orderList, setOrderList] = useState<MedicalOrderUser[]>([]);
  const [orderId, setOrderId] = useState<string>('');
  const [currentOrder, setCurrentOrder] = useState<OrderDetailType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetOrderList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await accountApi.getOrderList(userId);
      if (response.data.success) {
        const dataResponse = response.data.data.medicalOrders;
        setOrderList(dataResponse);
        setOrderId(dataResponse[0].id);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [userId]);

  const handleGetOrderDetail = useCallback(async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const getMedicalDetail = await medicalApi.getMedicalOrderDetail(orderId ?? '');
      if (getMedicalDetail.data.success) {
        setCurrentOrder(getMedicalDetail.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [orderId]);

  useEffect(() => {
    if (orderId) handleGetOrderDetail();
  }, [orderId, handleGetOrderDetail]);

  useEffect(() => {
    handleGetOrderList();
  }, [handleGetOrderList]);

  if (isLoading) {
    return <Skeleton className="mt-[24px]" />;
  }
  if (orderList?.length === 0) {
    return (
      <div className="w-full flex justify-center items-center pt-6">
        <p>No Data Available</p>
      </div>
    );
  }
  return (
    <div className="mt-2">
      <div className="flex flex-wrap h-fit w-full max-w-full">
        {orderList.map((data, index) => (
          <div
            className={` h-fit w-fit p-[10px] flex justify-center text-[16px] font-bold ${
              data.id === orderId
                ? 'text-primary bg-[#F2F3F5] rounded-2xl'
                : 'text-[#919191] cursor-pointer'
            } mt-[10px]`}
            onClick={() => {
              setOrderId(data.id);
              handleGetOrderDetail();
            }}
          >
            {`Order #${index + 1}`}
          </div>
        ))}
      </div>
      {currentOrder && (
        <div>
          {currentOrder.prescribedPack.length > 0 && (
            <>
              <div className="mt-[32px] mb-[32px]">
                <HorizontalLine />
              </div>
              <div>
                <PrescribedPackDetail
                  orderId={currentOrder.id ?? ''}
                  reloadData={handleGetOrderDetail}
                  status={currentOrder.status}
                  data={currentOrder.prescribedPack}
                  governmentIssueImg={currentOrder?.user?.identifications ?? []}
                />
              </div>
            </>
          )}
          {currentOrder?.orderSummary.length > 0 && (
            <OrderDetail OrderSummaryData={currentOrder?.orderSummary} />
          )}
        </div>
      )}
    </div>
  );
};

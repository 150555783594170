import { CloseCircleOutlined } from '@ant-design/icons';
import { naturopathicApi } from '@apis/naturopathic.api';
import { MedicalStatement } from '@constants/medical-statement';
import { Button, Form, Input, Modal } from 'antd';
import { useCallback, useState } from 'react';
import './medical-statement.style.scss';
interface Props {
  data: MedicalStatement | undefined;
  onOpen: boolean;
  onClose?: () => void;
  onModalOk?: () => void;
  onEditOk?: () => void;
}
export const MedicalStatementModal = ({
  data,
  onOpen,
  onClose = () => {},
  onEditOk = () => {},
}: Props) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const handleGetListStatement = useCallback(async (statement: string) => {
    try {
      const payload = {
        statement: statement,
      };
      const response = await naturopathicApi.updateStatement(data?.id ?? '', payload);
      if (response.data.success) {
        setIsEditing(false);
        onEditOk();
      }
    } catch (error) {
      setIsEditing(false);
    }
  }, []);

  const handleSubmitForm = (values: any) => {
    if (values.statement === data?.statement) {
      return onEditOk();
    }
    setIsEditing(true);
    handleGetListStatement(values.statement);
  };

  return (
    <Modal
      centered
      destroyOnClose
      open={onOpen}
      onOk={onClose}
      onCancel={onClose}
      className={`medical-statement-modal`}
      bodyStyle={{ backgroundColor: '#4D4479' }}
      title={
        <h2
          style={{
            fontWeight: 700,
            fontSize: 32,
            color: 'white',
            textAlign: 'center',
            marginTop: 40,
          }}
        >
          Edit Medical Statement
        </h2>
      }
      width={728}
      closeIcon={<CloseCircleOutlined className={`text-white text-[20px] pb-2`} />}
      footer={null}
    >
      <div className={`flex flex-col !pt-0 p-10`}>
        <Form
          layout="vertical"
          form={form}
          initialValues={data}
          onReset={onClose}
          onFinish={handleSubmitForm}
        >
          <Form.Item
            className="w-full "
            label={<p className={`text-white text-base m-0 p-0`}>Naturopathic</p>}
            rules={[{ required: true }]}
            required={false}
            name="naturopathicName"
          >
            <Input
              disabled
              className={`!h-[50px] border-[1px] border-solid border-white !bg-transparent rounded-[5px] !text-[#8C90AA] focus:!border-white hover:!border-white`}
              placeholder="Enter Product Name"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            required={false}
            className="statement-modal"
            label={<p className={`text-white text-base m-0 p-0`}>Statement </p>}
            initialValue={data}
            name={`statement`}
          >
            <Input.TextArea
              className={`!min-h-[180px] border-[1px] border-solid border-white !bg-transparent rounded-[5px] !text-[#FEFEFE] focus:!border-white hover:!border-white`}
              placeholder="Your statement"
            />
          </Form.Item>
        </Form>
        <div className="flex flex-row gap-3 w-full">
          <Button
            className={`w-full h-[60px] font-[700] rounded-[10px] bg-white text-primary border-none`}
            onClick={onClose}
          >
            Cancel
          </Button>
          {!isEditing ? (
            <Button
              onClick={() => form.submit()}
              htmlType="submit"
              className={`w-full h-[60px] rounded-[10px] bg-[#8B90C6] border-none`}
              type="primary"
            >
              Save
            </Button>
          ) : (
            <Button
              loading={isEditing}
              disabled={isEditing}
              htmlType="submit"
              className={`w-full h-[60px] rounded-[10px] bg-[#8B90C6] border-none`}
              type="primary"
            >
              Saving
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
